import React from 'react';
import { Button } from 'react-bootstrap';
import './Screen.css';
import { Link } from 'react-router-dom';
import powerslap from "./video/55.mp4";
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import styled from 'styled-components';
import ai from '../img/AODG26844.png';
import Typist from 'react-typist';

const Style9 = styled.div`




position: relative;


`



const Tre = styled.div`
@media only Screen and (min-width: 800px) {
  display: none;

  }
`;




function Screen() {
  return (
    <Style9>


    <div  id='home'  className="over">

    <video className="viv" autoPlay loop muted >

<source src={powerslap} type="video/mp4"/>

</video>



<Tre>

<div style={{  marginTop:"-15px"}} className='h'>

<div  className="gpt3__header section__padding" id="home">
  <div className="gpt3__header-content">
    <br/>  

    <div style={{ opacity:"0.8",borderRadius:"50px" , textAlign:"center" ,marginLeft:"auto",marginRight:"auto",marginTop:"auto",marginBottom:"-50px"}} className="hedertex" >
   


<h1 style={{color:"white",textAlign:"center"}} className="gradient__text">DJ </h1>      
<h1 style={{color:"white",textAlign:"center"}} className="gradient__text">Jordan Edri</h1>
<Typist>

    <h2 style={{color:"#DCE775" ,textAlign:"center" ,fontWeight:"bolder"}} className="gradient__text">מעל 15 שנות ניסיון בעולם המוזיקה</h2>
  
</Typist>


</div>



  </div>
  <div style={{borderRadius:"0px",textAlign:"center",marginBottom:"-15px" }} className="gpt3__header-image">

  <img className='im' style={{borderRadius:"0px",textAlign:"center" }} src={ai} />





  </div>



</div>

</div>


</Tre>


 
    </div>


    </Style9>

          
 

  )
}

export default Screen;





